import React from 'react'
import { 
    Info,
    Text
} from './ExperienceInfoElements'
import Certifications from '../Certifications/Certifications'
import MobileCertifications from '../MobileCertifications/MobileCertifications'

const ExperienceInfo = () => {
  return (
    <>
    <Info>
        <Text>
        I am a passionate and dedicated freelance developer, working independently since 2023. I hold a certification as a Full-Stack Web Developer from the University of Central Florida's edX program. My expertise lies in both front-end and back-end development, with strong proficiency in HTML, CSS, and JavaScript for crafting responsive and dynamic web interfaces.
        <br/>
        <br/>
        On the back-end, I am skilled in the MERN stack, encompassing MongoDB, Express.js, React.js, and Node.js, allowing me to build comprehensive full-stack applications. I have substantial experience in API design and integration, ensuring seamless communication between services. Additionally, I am adept at developing Progressive Web Applications (PWAs) and utilizing the MVC framework to maintain organized and efficient codebases. Additionally, my ability to collaborate effectively within diverse teams has been a cornerstone of my success, enabling me to contribute meaningfully to each project. I am committed to continuous learning and staying updated with modern web development practices to ensure the best outcomes.
        </Text>
    </Info>
    <Certifications />
    <MobileCertifications />
    </>
  )
}

export default ExperienceInfo