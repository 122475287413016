import styled from 'styled-components';

export const Alert = styled.div`
    background-color: var(--alert-red);
    padding: .5em 1em;;
    border-radius: 10px;
`;

export const AlertText = styled.p`
    color: var(--main-black);
    text-align: center;
`;