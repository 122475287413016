import styled from 'styled-components';
import Background from '../../assets/images/bg.png'


export const BannerContainer = styled.div`
    max-width: 2560px;
    margin: 0 auto;
    width: 100%;
`;

export const BannerBackground = styled.div`
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${Background});
    height: 100%;
    background-position: center;
    background-size: cover;
`;

export const BannerContent = styled.div`
    height: 100svh;
    width: 100%;
    margin-top: -4.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (min-width: 2000px) {
        height: 1000px
    }
`;

export const IntroContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 2560px;

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: start;
        height: 100%;
        margin-top: 18rem;
        justify-content: center;
    }
`;

export const IntroSection = styled.div`
    max-width: 48%;
    margin-left: 30px;

    @media (max-width: 1440px) {
        margin-left: 20px;
    }

    @media (max-width: 1024px) {
        max-width: 70%;
        margin-bottom: 5rem;
    }

    @media (max-width: 480px) {
        margin-bottom: 2rem;
        margin-left: 15px;
    }

    @media (max-width: 400px) {
        margin-bottom: 4rem;
    }
`;

export const Hello = styled.span`
    color: #fff;
    display: block;
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    text-shadow: -1px 2px 4px black;
    margin-left: 2px;

    @media (max-width: 480px) {
        margin-left: 0;
    }
`;

export const Name = styled.h1`
    color: #fff;
    display: block;
    font-size: 3rem;
    font-family: 'Inter', sans-serif;
    text-shadow: -1px 2px 3px black;
    line-height: .9em;

    @media (max-width: 768px) {
        font-size: 2.3rem;
    }

    @media (max-width: 480px) {
        font-size: 1.9rem;
    }
`;

export const Dev = styled.h2`
    color: #00BCE5;
    font-size: 3rem;
    font-family: 'Inter', sans-serif;
    text-shadow: -1px 2px 3px black;
    line-height: .8em;

    @media (max-width: 768px) {
        font-size: 2.3rem;
    }

    @media (max-width: 480px) {
        font-size: 1.9rem;
    }
`;

export const PhotoContainer = styled.div`
    width: 40%;
`;

export const Photo = styled.img`
    width: 30%;
    height: auto;
    position: absolute;
    right: 10%;

    @media (max-width: 1024px) {
        display: block;
        position: static;
        margin: 2rem auto 15rem auto;
        width: 30rem;
    }

    @media (max-width: 768px) {
        width: 35rem;
    }

    @media (max-width: 479px) {
        width: 25rem;
    }

    @media (max-width: 400px) {
        width: 23rem;
    }

    @media (max-width: 360px) {
        width: 19rem;
    }
`;