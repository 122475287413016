import React from 'react'

import {
    Alert,
    AlertText
} from './ErrorAlertElements'

const ErrorAlert = () => {
  return (
    <Alert>
        <AlertText>
        There was an issue with your submission. Please ensure all required fields are correctly filled out and try again.        </AlertText>
    </Alert>
  )
}

export default ErrorAlert