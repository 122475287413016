import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation SendMessage($firstName: String!, $lastName: String!, $email: String!, $message: String!) {
    sendMessage(firstName: $firstName, lastName: $lastName, email: $email, message: $message) {
      _id
      firstName
      lastName
      email
      message
    }
  }
`;

