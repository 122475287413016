import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import { 
    FormContainer,
    FormName,
    NameContainer,
    First,
    Last,
    Input,
    Message,
    SubmitButton,
} from './ContactFormElements'

import { useMutation } from '@apollo/client';
import { SEND_MESSAGE } from '../../utils/mutations';
import SentAlert  from '../SentAlert/SentAlert';
import ErrorAlert from '../ErrorAlert/ErrorAlert';



const ContactForm = () => {
  const [sendMessage, { error }] = useMutation(SEND_MESSAGE);
  const [sentAlert, setSentAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const sentAlertMessage = () => setSentAlert(true);

  // SEND EMAIL
  const form = useRef();

  const sendEmail = () => {

    emailjs
      .sendForm('service_isrh6jw', 'template_jb1ene6', form.current, {
        publicKey: '4CKBy22sroVtwN03m',
      })
      .then(
        () => {
          sentAlertMessage();
        },
        (error) => {
          console.log('FAILED...', error.text);

        },
      );
  }

  // SEND MESSAGE FORM
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });
  
  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    // On form submit perform mutation using form data
    try {
      const { data } = await sendMessage({
        variables: { ...formState },
      });
        // When message is sent successfully then call send email and set alert state
        await sendEmail();
        setSentAlert(true);
        setErrorAlert(false);
    } catch (err) {
      // If message has an error then set alert state
      console.error(err);
      setErrorAlert(true);
      setSentAlert(false);
    }
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
  
    if (name === 'firstName') {
        setFormState({ ...formState, [name]: value });
    } else if (name === 'lastName') {
        setFormState({ ...formState, [name]: value });
    } else if (name === 'email') {
        setFormState({ ...formState, [name]: value });
    } else if (name === 'message') {
        setFormState({ ...formState, [name]: value });
    }
  };

  return (
    <FormContainer>
      <form ref={form} onSubmit={handleFormSubmit}>
        <NameContainer>
          <First>
            <FormName>First</FormName>
            <Input type="text" name='firstName' placeholder="First Name" id='firstName' onChange={handleChange}/>
          </First>
          <Last>
            <FormName>Last</FormName>
            <Input type="text" name='lastName' placeholder="Last Name" id='lastName' onChange={handleChange}/>
          </Last>
        </NameContainer>
        <FormName>Email</FormName>
        <Input type="text" name='email' placeholder="Email" id='email' onChange={handleChange}/>
        <FormName>Message</FormName>
        <Message type="text" name='message'  placeholder="Message..." id='message' onChange={handleChange} rows={8}/>
        <SubmitButton type='submit'>Submit Message</SubmitButton>
        { sentAlert ? <SentAlert /> : null }
        { errorAlert ? <ErrorAlert /> : null}
      </form>
    </FormContainer>
  )
}

export default ContactForm