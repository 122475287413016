import React from 'react'
import { 
    AboutMeContainer,
    AboutMeContent,
    AboutTitle
} from './AboutMeSectionElements'
import AboutCard from '../AboutCard/AboutCard'



const AboutMe = () => {
  return (
    <AboutMeContainer id='About'>
        <AboutMeContent>
            <AboutTitle>Who am I?</AboutTitle>
            <AboutCard />
        </AboutMeContent>
    </AboutMeContainer>
  )
}

export default AboutMe