import React from 'react'
import { 
    FooterContainer,
    FooterContent,
    LinkSection,
    Links,
    Link,
    Copyright,
    Section,
    Title
} from './FooterSectionElements'
import TopButton from '../TopButton/TopButton'

const FooterSection = () => {
  return (
    <FooterContainer>
        <FooterContent>
            <LinkSection>
                <Section>
                    <Title>Navigation</Title>
                    <Links>
                        <Link href='#About'>Who am I?</Link>
                        <Link href='#Experience'>Experience</Link>
                        <Link href='#Portfolio'>Portfolio</Link>
                        <Link href='#Contact'>Contact</Link>
                    </Links>
                </Section>
                <Section>
                    <Title>Contact</Title>
                    <Links>
                        <Link 
                            href="mailto:petechicchetti@gmail.com">
                                petechicchetti@gmail.com
                        </Link>
                        <Link 
                            href="https://www.linkedin.com/in/peter-chicchetti-268a39224/" 
                            target="_blank" 
                            rel="noopener noreferrer">
                                LinkedIn/PeteChicchetti
                        </Link>
                        <Link
                            href="https://github.com/PeteChicchetti"
                            target="_blank"
                            rel="noopener noreferrer">
                                GitHub/PeteChicchetti
                        </Link>
                    </Links>
                </Section>
                <TopButton />
            </LinkSection>
            <Copyright>© All Rights Reserved</Copyright>
        </FooterContent>
    </FooterContainer>
  )
}

export default FooterSection