import styled from 'styled-components'

export const CertContainer = styled.div`
    width: 100%;

    // @media (max-width: 1024px) {
    //     width: 50%;
    // }

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 480px) {
        display none;
    }
`;

export const Title = styled.div`
    width: 100%;
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    text-shadow: -1px 2px 5px var(--main-black);
    color: var(--main-blue);
    margin-bottom: 2px;
`;

export const Subtitle = styled.div`
    font-size: .8rem;
    font-family: sans-serif;
    text-shadow: -1px 2px 5px black;
    color: var(--main-white);
    margin-bottom: .7rem;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
`;

export const Image = styled.img`
    width: 5rem;
    height: 5rem;
    box-shadow: 1px 1px 8px #000;
    border-radius: 10px;
    padding: .5rem;

    @media (max-width: 1024px) {
        width: 6rem;
        height: 6rem;
    }

    @media (max-width: 430px) {
        width: 5rem;
        height: 5rem;
    }
`;

export const ContentContainer= styled.div`
    width: 70%;
    display flex;
    flex-direction: column;
`;

export const Description = styled.div`
    font-size: .8rem;
    font-family: sans-serif;
    line-height: 1rem;
    text-shadow: -1px 2px 5px var(--main-black);
    color: var(--main-white);
    width: 100%;
    margin: 0 .6rem .3rem .6rem;
`;

export const Date = styled.div`
    font-size: .7rem;
    font-family: sans-serif;
    text-shadow: -1px 2px 5px var(--main-black);
    color: var(--main-white);
    width: 100%;
    margin: 0 .5rem;
`;

