import React from 'react'
import { 
    CardSection,
    Card,
    TopContainer,
    BottomContainer,
    ProjectInfo,
    ImageLink,
    Image,
    Title,
    InfoTitle,
    Description,
    TechTitle,
    TechList,
    TechItems,
    LinksContainer,
    CardLink,
    Tag,
} from './ProjectCardElements'
import { IoGlobeOutline } from "react-icons/io5";
import { FaGithub } from "react-icons/fa";


const projects = [
    {
      id: '1',
      image: require('../../assets/images/thrustaerogroup.png'),
      title: 'Thrust Aero Group',
      description: 'This front-end website was developed for Thrust Aero Group, a company specializing in aircraft sales, engine sales, parts supply, equipment, and repairs.',
      tech: [
        'HTML', ' • ', 'CSS', ' • ', 'JavaScript'
        ],
      github: 'https://github.com/PeteChicchetti/Thrust-Aero-Group',
      web: 'https://www.thrustaerogroup.com',
      tag: 'Freelance'
    },
    {
      id: '2',
      image: require('../../assets/images/vivovirtuoso.png'),
      title: 'VivoVirtuoso',
      description: 'This single-page front-end website features an input field that simultaneously searches two APIs to retrieve information about an artist and similar artists.',
      tech: [
        'HTML', ' • ', 'CSS', ' • ', 'JavaScript', ' • ', 'API', ' • ', 'Bulma', ' • ', 'GitHub Pages'
        ],
      github: 'https://github.com/Tristan-Ballin/VivoVirtuoso',
      web: 'https://tristan-ballin.github.io/VivoVirtuoso/',
      tag: 'Colaborative Project'
    },
    {
      id: '3',
      image: require('../../assets/images/technigram.png'),
      title: 'Technigram',
      description: 'Technigram is a MERN stack platform and social site designed for receiving tech news and sharing information with other users through a technical forum.',
      tech: [
        'HTML', ' • ', 'CSS', ' • ', 'ES6+', ' • ', 'React', ' • ', 'Styled Components',' • ', 'Node.js', ' • ', 'Express.js', ' • ', 'API', ' • ', 'GraphQL', ' • ', 'MongoDB', ' • ', 'JWT', ' • ', 'Bcrypt', ' • ', 'React Bootstrap', ' • ', 'Heroku'
        ],
      github: 'https://github.com/PeteChicchetti/technigram',
      web: 'https://murmuring-depths-50021.herokuapp.com/',
      tag: 'Colaborative Project'
    },
    {
      id: '4',
      image: require('../../assets/images/jate.png'),
      title: 'J.A.T.E',
      description: 'Just Another Text Editor is a Progressive Web Application (PWA) that is installable and allows users to save notes to an IndexedDB for offline use.',
      tech: [
        'Progrssive Web-App', ' • ', 'Indexed DB', ' • ', 'Heroku'
        ],
      github: 'https://github.com/PeteChicchetti/pwa-text-editor',
      web: 'https://afternoon-bastion-22644.herokuapp.com/',
      tag: 'Personal Project'
    }
]

let data = projects.map((projects) => 
    <>
    <Card>
      <TopContainer>
        <ProjectInfo>
          <Title>{ projects.title }</Title>
          <Tag>{ projects.tag }</Tag>
          <LinksContainer>
            <CardLink 
                href={ projects.github }
                target="_blank"
                rel="noopener noreferrer">
                  GitHub <FaGithub style={{marginLeft: '5px', marginBottom: '1px'}} />
            </CardLink>
            <CardLink 
                href={ projects.web }
                target="_blank"
                rel="noopener noreferrer">
                  Web <IoGlobeOutline style={{marginLeft: '5px', marginBottom: '1px'}} />
            </CardLink>
          </LinksContainer>
        </ProjectInfo>
        <ImageLink
          href={ projects.web }
          target="_blank"
          rel="noopener noreferrer">
          <Image src={ projects.image } />
        </ImageLink>
      </TopContainer>
      <BottomContainer>
        <InfoTitle>Info</InfoTitle>
        <Description>{ projects.description }</Description>
        <TechTitle>Tech</TechTitle>
        <TechList>
          <TechItems>{ projects.tech }</TechItems>
        </TechList>
      </BottomContainer>
    </Card>
    </> 
);


const ProjectCard = () => {
  return (
    <CardSection>
            { data }
    </CardSection>
  )
}

export default ProjectCard