import styled from 'styled-components'

export const FormContainer = styled.div`
    position: relative;
    border-left: 2px solid var(--main-blue);
    padding-left: 1rem;
    width: 100%;

    @media (max-width: 1024px) {
        padding: 0;
        border-left: 0;
    }
`;

export const FormName = styled.div`
    font-size: 1.2rem;
    font-family: var(--main-font);
    color: var(--main-blue);
    text-shadow: -1px 2px 6px black;
    margin: 0 0 .1rem .4rem;
`;

export const NameContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 490px) {
        flex-direction: column;
    }
`;

export const First = styled.div`
    width: 48%;

    @media (max-width: 490px) {
        width: 100%;
    }
`;

export const Last = styled.div`
    width: 48%;

    @media (max-width: 490px) {
        width: 100%;
    }
`;

export const Input = styled.input`
    height: 2.4rem;
    width: 100%;
    border-radius: 10px;
    border: none;
    padding: 2px 10px;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-style: bold;
`;

export const Message = styled.textarea`

    width: 100%;
    border-radius: 10px;
    border: none;
    padding: 5px 10px;
    font-size: 1.2rem;
    font-style: bold;
    resize: none;
    
    ::-webkit-scrollbar {
        width: 12px;
    }
     
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 1 6px rgba(0,0,0,0.3); 
        border-radius: 10px;
    }
     
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }
`;

export const SubmitButton = styled.button`
    width: 100%;
    border: 2px solid var(--main-blue);
    border-radius: 10px;
    background: var(--main-blue);
    color: var(--main-white);
    text-shadow: -1px 1px 6px var(--main-black);
    text-align: center;
    padding: .5em 1em;
    margin: .3rem .6rem .3rem 0;
    cursor: pointer;

    :hover {
        background: transparent;
        color: var(--main-blue);
    }

    @media (max-width: 540px) {
        margin: .2rem 0 .2rem 0;
`;