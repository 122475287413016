import React from 'react';
import { 
    MenuContainer, 
    CloseIcon, 
    Icon, 
    MobileWrapper, 
    MobileDropdown, 
    MobileLink, 
    MobileBtnWrapper, 
    ResumeButton
} from './MobileMenuElements';

import Resume from '../../assets/Pete-Chicchetti-Resume.pdf'


const MobileMenu = ({ isOpen, toggle }) => {
  return (
    <MenuContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <MobileWrapper>
            <MobileDropdown>
                <MobileLink href="#About" onClick={toggle}>Who am I?</MobileLink>
                <MobileLink href="#Experience" onClick={toggle}>Experience</MobileLink>
                <MobileLink href="#Portfolio" onClick={toggle}>Portfolio</MobileLink>
                <MobileLink href="#Contact" onClick={toggle}>Contact</MobileLink>
            </MobileDropdown>
            <MobileBtnWrapper>
                <ResumeButton 
                    onClick={toggle}
                    href={Resume}
                    download='Pete-Chicchetti-Resume.pdf'>
                        Resume
                </ResumeButton>
            </MobileBtnWrapper>
        </MobileWrapper>
    </MenuContainer>
  )
}

export default MobileMenu;