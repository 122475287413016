import React from 'react'
import { 
    CertContainer,
    Content,
    ContentContainer,
    Date,
    Description,
    Image,
    Subtitle,
    Title
} from './CertificationsElements'
import Cert from '../../assets/images/UCF-Cert.png'

const Certifications = () => {
  return (
    <CertContainer>
        <Title>UCF Coding Boot Camp (Full-Stack Web Development)</Title>
        <Subtitle>UCF Coding Boot Camp in partnership with edX</Subtitle>
        <Content>
            <Image src={Cert} />
            <ContentContainer>
                <Description>
                    Demonstrated a fundamental working knowledge of HTML, CSS, Javascript, third-party APIs, Progressive Web Applications, MySQL, API design, and the MERN stack (MongoDB, Express.js, React.js, Node.js). A proven ability to work with diverse teams on demanding timelines to build full functional web applications from start to finish.
                </Description>
                <Date>
                    September 2022 - December 2022
                </Date>
            </ContentContainer>
        </Content>
    </CertContainer>
  )
}

export default Certifications