import styled from 'styled-components'

export const SocialsContainer = styled.div`
    text-align: center;
    position: absolute;
    bottom: .6rem;
    right: .6rem;
    margin-right: .6rem;

    @media (max-width: 480px) {
        display: flex;
    }

`;

export const Line = styled.div`
    width: 1px;
    height: 4rem;
    background-color: var(--main-grey);
    margin: 0 auto .7rem auto;

    @media (max-width: 480px) {
        display: none;
    }
`;

export const GitHub = styled.div`
    width: 50px;
    height: 50px;
    background-color: var(--main-grey);
    border-radius: 25px;
    margin: .5rem 0;
`;

export const LinkedIn = styled.div`
    width: 50px;
    height: 50px;
    background-color: var(--main-grey);
    border-radius: 25px;
    margin: .5rem 0;
    display: 'flex';
    justifyContent: 'center';
    alignItems: 'center';

    @media (max-width: 480px) {
        margin-left: 10px;
    }
`;