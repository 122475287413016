import React from 'react'
import { 
  ContactContainer,
  ContactContent,
  ContactTitle,
  ContentContainer,
  LeftSection,
  RightSection
} from './ContactSectonElements'
import ContactInfo from '../ContactInfo/ContactInfo'
import ContactForm from '../ContactForm/ContactForm'

const ContactSection = () => {
  return (
    <ContactContainer id='Contact'>
        <ContactContent>
            <ContactTitle>Contact</ContactTitle>
            <ContentContainer>
                <LeftSection>
                  <ContactInfo />
                </LeftSection>
                <RightSection>
                  <ContactForm />
                </RightSection>
            </ContentContainer>
        </ContactContent>
    </ContactContainer>
  )
}

export default ContactSection