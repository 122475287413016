import styled from 'styled-components'

export const ContactContent = styled.div`
    max-width: 90%;

    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 2px solid var(--main-blue);
    padding: .7rem 1rem 0 1rem;
    margin-bottom: 3rem;
`;

export const Link = styled.a`
    font-size: .8rem;
    font-family: 'Inter' sans-sarif;
    color: var(--main-white);
    text-shadow: -1px 2px 5px var(--main-black);
    padding-bottom: .7rem;
`;

export const TextSection = styled.div`
    border-left: 2px solid var(--main-blue);
    padding: 1rem 0 1rem 1rem;

    @media (max-width: 1024px) {
        margin-bottom: 3rem;
    }
`;

export const Title = styled.h1`
    font-size: 1.3rem;
    font-family: 'Inter' sans-sarif;
    letter-spacing: 1px;
    color: var(--main-blue);
    text-shadow: -1px 1px 6px var(--main-black);
    margin-bottom: 1rem;

    @media (max-width: 480px) {
        font-size: 1.1rem;
    }

    @media (max-width: 400px) {
        font-size: 1rem;
    }
`;

export const Text = styled.p`
    font-size: .9rem;
    font-family: 'Inter' sans-sarif;
    color: var(--main-white);
    text-shadow: -1px 2px 5px var(--main-black);
`;

