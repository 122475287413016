import styled from "styled-components"

export const InfoContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 1rem;

    @media (max-width: 1024px) {
        padding: 1rem 0 0 0;
    }
`;

export const Name = styled.div`
    font-size: 1.8rem;
    font-family: var(--title-font);
    color: var(--main-blue);
    text-shadow: -1px 2px 4px black;
`;

export const Job = styled.div`
    font-size: 1rem;
    font-family: var(--title-font);
    font-style: italic;
    color: var(--main-white);
    text-shadow: -1px 2px 5px black;
`;

export const Information = styled.div`
    font-size: .9rem;
    font-family: sans-serif;
    color: var(--main-white);
    text-shadow: -1px 2px 5px black;
    margin: 2rem 0;

    @media (max-width: 540px) {
        margin: 2rem 0 .8rem 0;
    }
`;

export const ParagraphOne = styled.div`
    margin: 1rem 0;
`;

export const ParagraphTwo = styled.div`
    margin: 1rem 0 2rem 0;

    @media (max-width: 540px) {
        margin-bottom: 1.2rem;
    }
`;

export const Email = styled.a`
    display: flex;
    align-items: center;
    font-size: .8rem;
    color: var(--main-white);
    margin-bottom: 2rem;

    @media (max-width: 540px) {
        margin: 0 0 1.2rem 0;
    }
`;