import styled from 'styled-components'

export const FooterContainer = styled.div`
    background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), var(--main-blue);
    max-width: 2560px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
`;

export const FooterContent = styled.div`
    max-width: 80%;
    margin: 0 auto;
`;

export const LinkSection = styled.div`
    display: flex;
    padding: 2rem 0;
    margin-left: 4rem;

    @media (max-width: 1024px) {
        margin-left: .8rem
    }

    @media (max-width: 430px) {
        flex-direction: column;
        text-align: center;
        margin-left: 0;
    }
`;

export const Title = styled.div`
    font-size: .8rem;
    font-family: 'Inter', sans-serif;
    color: var(--main-black);
    letter-spacing: 1px;
`;

export const Section = styled.div`
    margin-right: 4rem;

    @media (max-width: 1024px) {
        margin-right: 2rem;
    }

    @media (max-width: 430px) {
        margin: 20px 0;
    }
`;

export const Links = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--main-black);
    padding: .1rem 0;
    margin: .3rem .5rem 0 .3rem;

    @media (max-width: 430px) {
        border-left: none;
    }
`;

export const Link = styled.a`
    font-size: .7rem;
    font-family: sans-serif;
    letter-spacing: 1px;
    color: var(--main-black);
    padding: .4rem .4rem .4rem .7rem;

    @media (max-width: 430px) {
        padding: .4rem 0;
    }
`;

export const Copyright = styled.div`
    font-size: .6rem;
    font-weight: bold;
    font-family: sans-serif;
    color: var(--main-black);
    width: 100%
    display: flex;
    align-items: center;
    text-align: center;
    padding: 1.5rem 0;
    border-top: 1px solid var(--main-black);
`;