import React from 'react'

import {
    Card,
    AboutInfo,
    CardContent,
    PhotoContainer
} from '../AboutCard/AboutCardElements'
import { HeadshotImg } from '../Headshot/HeadshotElements'
import MyInfo from '../MyInfo/MyInfo'

import HeadshotSrc from '../../assets/images/headshot.png'


const AboutCard = () => {
  return (
    <Card>
        <CardContent>
            <PhotoContainer>
                <HeadshotImg src={HeadshotSrc}/>
            </PhotoContainer>
            <AboutInfo>
                <MyInfo />
            </AboutInfo>
        </CardContent>
    </Card>
  )
}

export default AboutCard;