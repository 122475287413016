import styled from 'styled-components';

export const Nav = styled.nav`
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    width: 100%;

    @media (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    margin: .5rem;
    max-width: 2560px;
`;

export const NavLogoContainer = styled.a`
    display: flex;
    align-items: center;
`;

export const Logo = styled.img`
    height: 3.2rem;
    width: auto;

    @media (max-width: 479px) {
        height: 2.5rem
    }
`;

export const MobileIcon = styled.div`
    display: none;

    @media (max-width: 820px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--main-white);
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    list-style: none;

    @media (max-width: 820px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    display: flex;
    align-items: center;
    margin-right: 2.9rem;
`;

export const NavLinks = styled.a`
    color: var(--main-white);
    display: flex;
    text-decoration: none;
    text-shadow: -1px 1px 6px black;
    padding: 0 1rem;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        text-underline-offset: .2rem;
    }
`;