import React from 'react'
import { 
    ContactContent,
    Info,
    Link,
    TextSection,
    Title,
    Text
} from './ContactInfoElements'

const ContactInfo = () => {
  return (
    <ContactContent>
        <Info>
            <Link 
              href="mailto:petechicchetti@gmail.com"
              target="_blank"
              rel="noopener noreferrer">
                Send me an email!
            </Link>
            <Link 
              href="https://github.com/PeteChicchetti"
              target="_blank"
              rel="noopener noreferrer">
                Check out my GitHub!
            </Link>
            <Link 
              href="https://www.linkedin.com/in/peter-chicchetti-268a39224/"
              target="_blank"
              rel="noopener noreferrer">
                Reach out on LinkedIn!
            </Link>
        </Info>
        <TextSection>
            <Title>
              Need a Full-Stack Web Developer?
              <br />
              Let's get in touch!
            </Title>
            <Text>
              Thank you for your interest in connecting with me. If you have any inquiries regarding freelance work, job opportunities, or a collaboration, please feel free to reach out through the contact form on my website, sending an email, or connect with me on LinkedIn.
              <br />
              <br />
              When you use the contact form I will get back to you at the email address you provided as soon as possible. For a more direct approach, you can email me directly at <a style={{color: 'var(--main-blue)'}} href="mailto:petechicchetti@gmail.com">petechicchetti@gmail.com</a>. Additionally, you can find me on LinkedIn, where we can connect and discuss potential opportunities.
              <br />
              <br />
              I am excited about the possibility of working together and look forward to hearing from you soon!
            </Text>
        </TextSection>
    </ContactContent>
  )
}

export default ContactInfo