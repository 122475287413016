import React from 'react'
import { GitHub, Line, LinkedIn, SocialsContainer } from './SocialIconsElements'

import { 
    FaLinkedinIn,
    FaGithub
} from "react-icons/fa";

const SocialIcons = () => {
  return (
    <SocialsContainer>
        <Line />
            <GitHub>
                <a 
                    href="https://github.com/PeteChicchetti"
                    target="_blank"
                    rel="noopener noreferrer">
                    <FaGithub
                        style={{
                            color: 'var(--main-blue)',
                            height: '35px',
                            width: '35px',
                            margin: '7px 0'
                    }}/>
                </a>
            </GitHub>
            <LinkedIn>
                <a 
                    href="https://www.linkedin.com/in/peter-chicchetti-268a39224/" 
                    target="_blank" 
                    rel="noopener noreferrer">
                    <FaLinkedinIn 
                        style={{
                            color: 'var(--main-blue)',
                            height: '30px',
                            width: '30px',
                            margin: '10px 0'
                    }}/>
                </a>
            </LinkedIn>
    </SocialsContainer>
  )
}

export default SocialIcons