import React from "react";
import {
    BannerContainer,
    BannerBackground,
    BannerContent,
    IntroContainer,
    IntroSection,
    Hello,
    Name,
    Dev,
    Photo
} from './BannerSectionElements';

import MeSrc from '../../assets/images/me.png'
import SocialIcons from "../SocialIcons/SocialIcons";


const Banner = () => {
    return (
        <>
        <BannerContainer id='Top'>
            <BannerBackground>
                <BannerContent>
                    <IntroContainer>
                        <IntroSection>
                            <Hello>
                                Hi, my name is
                            </Hello>
                            <Name>
                                Pete Chicchetti.
                            </Name>
                            <Dev>
                                Full-Stack Web Developer
                            </Dev>
                        </IntroSection>
                        <Photo src={MeSrc}/>
                    </IntroContainer>
                    <SocialIcons />
                </BannerContent>
            </BannerBackground>
        </BannerContainer>
        </>
    )
}

export default Banner;