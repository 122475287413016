import styled from 'styled-components'

export const ContactContainer = styled.div`
    background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), var(--main-grey);
    max-width: 2560px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
`;

export const ContactContent = styled.div`
    max-width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 7rem 0 12rem 0;
    margin: 0 auto;

    @media (max-width: 1024px) {
        padding: 4rem 0 8rem 0;
    }
`;

export const ContactTitle = styled.div`
    width: 100%;
    font-size: 3rem;
    font-family: 'Inter', sans-serif;
    text-shadow: -1px 2px 3px var(--main-black);
    color: var(--main-blue);
    margin-bottom: 5rem;

    @media (max-width: 480px) {
        font-size: 2.1rem;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    width: 90%;

    @media (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const LeftSection = styled.div`
    width: 60%;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export const RightSection = styled.div`
    width: 40%;
    
    @media (max-width: 1024px) {
        width: 100%;
    }
`;