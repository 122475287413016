import React from 'react'
import { 
    ButtonContainer,
    DownloadButton,
    ContactButton
} from './AboutButtonsElements'

import { FiDownload } from "react-icons/fi";
import { BiMessage } from "react-icons/bi";

import Resume from '../../assets/Pete-Chicchetti-Resume.pdf'

const AboutButtons = () => {
  return (
    <ButtonContainer>
        <DownloadButton
          href={Resume}
          download='Pete-Chicchetti-Resume.pdf'>
            Download Resume
          <FiDownload 
              style={{
                  marginLeft: '.6rem',
                  height: '1rem',
                  width: '1rem'
              }}/>
        </DownloadButton>
        <ContactButton 
          href='#Contact'>
            Contact Me
              <BiMessage 
                style={{
                  marginLeft: '.6rem',
                  height: '1rem',
                  width: '1rem'
                }}/>
        </ContactButton>
    </ButtonContainer>
  )
}

export default AboutButtons