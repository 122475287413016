import React from 'react'

import {
    TechCard,
    CardBody,
    TitleSection,
    TextSection,
    CardTitle,
    CardSubtitle,
    Title,
    CardText
} from './TechInfoElements'

const TechInfo = () => {
  return (
    <TechCard>
        <CardBody>
            <TitleSection>
                <CardTitle>Technologies and Tools</CardTitle>
                <CardSubtitle>Front-End, Back-End, and Other tools used in development.</CardSubtitle>
            </TitleSection>
            <TextSection>
                <Title>Front-End</Title>
                <CardText>
                    HTML • CSS • JavaScript • ES6+ • React • Bootstrap • Styled-components
                </CardText>
            </TextSection>
            <TextSection>
                <Title>Back-End</Title>
                <CardText>
                    Node.js • NPM • MVC Framework • Express.js • MySQL • MongoDB • GraphQL  • Sequelize • Heroku
                </CardText>
            </TextSection>
            <TextSection>
                <Title>Other</Title>
                <CardText>
                    VS Code • GitHub • Postman • Figma • Git
                </CardText>
            </TextSection>
        </CardBody>
    </TechCard>
  )
}

export default TechInfo