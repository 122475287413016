import React from 'react'
import { 
    ExperienceContainer,
    ExperienceContent,
    ExperienceTitle,
    ContentContainer,
    LeftSection,
    RightSection,
} from './ExperienceSectionElements'
import ExperienceInfo from '../ExperienceInfo/ExperienceInfo'
import TechInfo from '../TechInfo/TechInfo'


const Experience = () => {
  return (
    <ExperienceContainer id='Experience'>
        <ExperienceContent>
            <ExperienceTitle>Experience</ExperienceTitle>
            <ContentContainer>
              <LeftSection>
                  <ExperienceInfo />
              </LeftSection>
              <RightSection>
                  <TechInfo />
              </RightSection>
          </ContentContainer>
        </ExperienceContent>
    </ExperienceContainer>
  )
}

export default Experience