import React from 'react'

import {
    Alert,
    AlertText
} from './SentAlertElements'

const SentAlert = () => {
  return (
    <Alert>
        <AlertText>
            Your message has been sent!
        </AlertText>
    </Alert>
  )
}

export default SentAlert