import React from 'react'
import { 
    PortfolioContainer,
    PortfolioTitle,
    PortoflioContent,
    ProjectContainer
} from './PortfolioSectionElements'
import ProjectCard from '../ProjectCard/ProjectCard'

const PortfolioSection = () => {
  return (
    <PortfolioContainer id='Portfolio'>
        <PortoflioContent>
            <PortfolioTitle>Portfolio</PortfolioTitle>
            <ProjectContainer>
              <ProjectCard />
            </ProjectContainer>
        </PortoflioContent>
    </PortfolioContainer>
  )
}

export default PortfolioSection