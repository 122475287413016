import styled from 'styled-components';

export const Card = styled.div`
    background-color: var(--main-grey);
    box-shadow: 1px 1px 8px #000; 
    border-radius: 10px; 
    padding: 1.5rem;
    width: 80%;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export const CardContent = styled.div` 
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const PhotoContainer = styled.div`
    display: flex;
    width: 30%;
    height 30%;

    @media (max-width: 1024px) {
        width: 55%;
        height: 55%;
        margin-bottom: 1.2rem;
    }
`;

export const AboutInfo = styled.div`
    width 60%;
    border-left: 2px solid var(--main-blue);

    @media (max-width: 1024px) {
        border-left: none;
        border-top: 2px solid var(--main-blue);
        width: 100%;
    }
`;