import React from 'react'
import { 
    Container,
    Line,
    Text
} from './TopButtonElements'

const TopButton = () => {

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }


  return (
    <Container>
        <Line onClick={topFunction}></Line>
        <Text onClick={topFunction}>BACK TO TOP</Text>
    </Container>

  )
}

export default TopButton