import styled from 'styled-components'

export const TechCard = styled.div`
    max-width: 500px;
    min-width: 300px;
    box-shadow: 1px 1px 8px #000;
    border-radius: 10px;
    padding: 1rem;
    margin: 0 auto;

    @media (max-width: 1024px) {
        width: 100%;
        max-width: none;
    }

    @media (max-width: 400px) {
        min-width: 270px;
    }
`;

export const CardBody = styled.div`
    width: 100%;
    height: 100%;
`;

export const TitleSection = styled.div`
    border-bottom: 1px solid var(--main-blue);
    text-shadow: -1px 2px 5px black;
    padding-bottom: .3rem;
    margin: 0 0 1.3rem 0;
`;

export const CardTitle = styled.div`
    font-size: 1.1rem;
    font-family: var(--title-font);
    text-shadow: -1px 2px 6px black;
    color: var(--main-blue)
`;

export const CardSubtitle = styled.div`
    font-size: .7rem;
    color: var(--main-white);
    text-shadow: -1px 2px 5px black;
`;

export const TextSection = styled.div`
    margin: .6rem 0;
    text-shadow: -1px 2px 5px black;
`;

export const Title = styled.div`
    font-size: 1rem;
    color: var(--main-blue);
`;

export const CardText = styled.div`
    font-size: .8rem;
    color: var(--main-white);
`;