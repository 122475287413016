import React from 'react';
import { useState } from 'react';
import { FaBars} from 'react-icons/fa';
import { 
  Nav,
  NavbarContainer,
  NavLogoContainer,
  // NavLogo,
  Logo,
  MobileIcon, 
  NavMenu,
  NavItem,
  NavLinks
} from './NavBarElements';

import LogoWideSrc from '../../assets/images/logo-wide.png'

const Navbar = ({ toggle }) => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
      if (window.scrollY >= 80) {
          setColorchange(true);
      }
      else {
          setColorchange(false);
      }
  };
  window.addEventListener('scroll', changeNavbarColor);
  return (
    <>
        <Nav className={colorChange ? 'navbar colorChange' : 'navbar'}>
          <NavbarContainer>
            <NavLogoContainer href='/'>
              <Logo src={LogoWideSrc} ></Logo>
            </NavLogoContainer>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks href="#About" id="nav-link">Who am I?</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks href="#Experience" id="nav-link">Experience</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks href="#Portfolio" id="nav-link">Portfolio</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks href="#Contact" id="nav-link">Contact</NavLinks>
              </NavItem>
            </NavMenu> 
          </NavbarContainer>  
        </Nav>
    </>
  )
}

export default Navbar;