import styled from "styled-components";

export const ButtonContainer = styled.div`
    display: flex;

    @media (max-width: 540px) {
        flex-direction: column;
    }
`;

export const DownloadButton = styled.a`
    display: flex;
    align-items: center;
    font-size: .8rem;
    border: 2px solid var(--main-blue);
    border-radius: 10px;
    background: var(--main-blue);
    color: var(--main-white);
    text-shadow: -1px 1px 6px var(--main-black);
    padding: .5em 1em;
    margin: .3rem .6rem .3rem 0;
    cursor: pointer;

    :hover {
        background: transparent;
        color: var(--main-blue);
    }

    @media (max-width: 540px) {
        justify-content: center;
        margin: .2rem 0 .2rem 0;
    }
`;

export const ContactButton = styled.a`
    display: flex;
    align-items: center;
    font-size: .8rem;
    border: 2px solid var(--main-blue);
    border-radius: 10px;
    background: var(--main-blue);
    color: var(--main-white);
    text-shadow: -1px 1px 6px var(--main-black);
    padding: .5em 1em;
    margin: .3rem .6rem .3rem 0;

    :hover {
        background: transparent;
        color: var(--main-blue);
    }

    @media (max-width: 540px) {
        justify-content: center;
        margin: .2rem 0 .2rem 0;
    }
`;