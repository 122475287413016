import styled from 'styled-components'


export const Container = styled.div`
    position: absolute;
    right: 1%;

    @media (max-width: 430px) {
        margin-top: 150px;
    }
`;

export const Line = styled.div`
    width: 1px;
    height: 3rem;
    background-color: var(--main-grey);
    margin: 0 auto 2rem auto;
    cursor: pointer;
`;

export const Text = styled.div`
    transform: rotate(0.75turn);
    font-size: .6rem;
    cursor: pointer;
`;