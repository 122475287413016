import styled from 'styled-components'

export const CardSection = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Card = styled.div`
    background-color: var(--main-grey);
    box-shadow: 1px 1px 10px 1px var(--main-black);
    border-radius: 10px;
    padding: 10px;
    margin: 24px 0;
    width: 45%;

    @media (max-width: 1024px) {
        width: 85%;
        margin: 20px 0;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 480px) {
        padding: 6px;
    }
`;

export const TopContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: .6rem;

    @media (max-width: 480px) {
        flex-direction: column-reverse;
    }
`;

export const ProjectInfo = styled.div`

`;

export const Title = styled.h3`
    font-size: 1.4rem;
    text-shadow: -1px 1px 6px var(--main-black);
    color: var(--main-white);
    margin-bottom: .6rem;
`;

export const LinksContainer = styled.div`
    display: flex;
    margin-top: 6px;
`;

export const CardLink = styled.a`
    font-size: .8rem;
    background-color: var(--main-light-grey);
    color: var(--main-white);
    text-shadow: 1px 1px 6px var(--main-black);
    padding: .2rem .5rem;
    margin-right: 8px;
    border-radius: 1rem;
    display: flex;
    align-items: center;

    :hover {
        background-color: var(--main-white);
        color: var(--main-light-grey);
        text-shadow: none;
    }
`;

export const Tag = styled.span`
    font-size: .8rem;
    background-color: var(--main-light-grey);
    color: var(--main-white);
    text-shadow: 1px 1px 6px var(--main-black);
    padding: .2rem .5rem;
    border-radius: 1rem;
`;

export const ImageLink = styled.a`

`;

export const Image = styled.img`
    width: 18rem;
    height: auto;

    @media (max-width: 1440px) {
        width: 100%;
    }

    @media (max-width: 1024px) {
        width: 23rem;
    }

    @media (max-width: 480px) {
        width: 100%;
        margin-bottom: 12px;
    }
`;

export const BottomContainer = styled.div`
    width: 100%;
    padding: .6rem;
`;

export const InfoTitle = styled.h4`
    font-size: 1rem;
    text-shadow: -1px 1px 6px var(--main-black);
    color: var(--main-blue);
`;

export const Description = styled.p`
    font-size: .9rem;
    text-shadow: -1px 2px 6px var(--main-black);
    color: var(--main-white);
    margin-bottom: 20px;
`;

export const TechTitle = styled.h4`
    font-size: 1rem;
    text-shadow: -1px 1px 6px var(--main-black);
    color: var(--main-blue);
`;

export const TechList = styled.ul`
    margin-bottom: 12px;
    text-shadow: -1px 2px 6px var(--main-black);
`;

export const TechItems = styled.li`
    font-size: .9rem;
    text-shadow: var(--text-shadow);
    color: var(--main-white);
`;