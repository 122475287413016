import styled from 'styled-components'

export const Info = styled.div`
    border-left: 2px solid var(--main-blue);
    padding: 1rem 0 0.1rem 1rem;
    margin-bottom: 1.8rem;

    @media (max-width: 1024px) {
        margin-bottom: 3rem;
    }
`;

export const Text = styled.div`
    font-size: .9rem;
    line-height: 1.2rem;
    font-family: sans-serif;
    color: var(--main-white);
    text-shadow: -1px 2px 5px black;
    padding-bottom: 1rem;
`;