import styled from 'styled-components';

export const MobileCertContainer = styled.div`
    @media (min-width: 481px) {
        display: none;
    }

    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const TitleSection = styled.div`
    display: flex;
`;

export const CertHeader = styled.div`
    margin-left: .8rem;
`;


export const Title = styled.div`
    width: 100%;
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    text-shadow: -1px 2px 5px var(--main-black);
    color: var(--main-blue);
    margin-bottom: 2px;

    @media (max-width: 415px) {
        font-size: 1rem;
    }
`;

export const Subtitle = styled.div`
    font-size: .8rem;
    font-family: sans-serif;
    text-shadow: -1px 2px 5px black;
    color: var(--main-white);
    margin-bottom: .7rem;

    @media (max-width: 415px) {
        font-size: .8rem;
    }
`;

export const Content = styled.div`
    width: 100%;
    margin-top: .5rem;
`;

export const Image = styled.img`
    width: 5rem;
    height: 5rem;
    box-shadow: 1px 1px 8px #000;
    border-radius: 10px;
    padding: .5rem;

    @media (max-width: 430px) {
        width: 4rem;
        height: 4rem;
    }
`;


export const Description = styled.div`
    font-size: .9rem;
    font-family: sans-serif;
    line-height: 1.1rem;
    text-shadow: -1px 2px 5px var(--main-black);
    color: var(--main-white);
    width: 100%;

`;

export const Date = styled.div`
    font-size: .8rem;
    font-family: sans-serif;
    text-shadow: -1px 2px 5px var(--main-black);
    color: var(--main-white);
    width: 100%;
    margin-top: .5rem;
`;

