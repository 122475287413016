import React, { useState } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

/// IMPORT COMPONENTS ///
import Navbar from './components/NavBar/NavBar';
import MobileMenu from './components/MobileMenu/MobileMenu';
import Banner from './components/BannerSection/BannerSection';
import AboutMe from './components/AboutMeSection/AboutMeSection';
import Experience from './components/ExperienceSection/ExperienceSection';
import Portfolio from './components/PortfolioSection/PortfolioSection';
import Contact from './components/ContactSection/ContactSection';
import Footer from './components/FooterSection/FooterSection';

import './App.css';



const httpLink = createHttpLink({
  uri: '/graphql',
});


/// SET UP CLIENT ///
const client = new ApolloClient({
  link: (httpLink),
  cache: new InMemoryCache(),
});

function App() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ApolloProvider client={client}>
      <Router>
        <MobileMenu isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle} />
        <Routes>
          <Route path='/' element={
              <>
                <Banner />
                <AboutMe />
                <Experience />
                <Portfolio />
                <Contact />
                <Footer />
              </>
            } />
        </Routes>
      </Router>
    </ApolloProvider>
  );
}

export default App;
