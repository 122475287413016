import React from 'react'
import { 
    InfoContainer,
    Name,
    Job,
    Information,
    ParagraphOne,
    ParagraphTwo,
    Email
 } from './MyInfoElements'

import { MdMailOutline } from "react-icons/md";

import AboutButtons from '../AboutButtons/AboutButtons';

const MyInfo = () => {
  return (
    <InfoContainer>
        <Name>
            PETE CHICCHETTI
        </Name>
        <Job>
            WEB DEVELOPER • FREELANCER
        </Job>
        <Information>
            <ParagraphOne>
                Hi, my name is Pete Chicchetti and I am a full stack web developer.
            </ParagraphOne>
            <ParagraphTwo>
                My path to becoming a web developer began in 2022 when I discovered a passion for coding. Shortly after, I joined the "Full-Stack Coding Bootcamp" by edX, offered through the University of Central Florida, where I received my initial certification. My interest in computers, problem-solving skills, and creative mindset quickly fueled my enthusiasm for web development.
            </ParagraphTwo>
            <Email
                href="mailto:petechicchetti@gmail.com">
                <MdMailOutline 
                    style={{ 
                        color: 'var(--main-blue)', 
                        margin: "0 .2rem 0 0", 
                        height: '1.1rem', 
                        width: '1.1rem' 
                    }}/>
                petechicchetti@gmail.com
            </Email>
        </Information>
        <AboutButtons />
    </InfoContainer>
  )
}

export default MyInfo;